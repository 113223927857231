.bg{
	background-color: #f8fafc !important;
}

.message{
	background-color: #ffffff!important;
	border-radius: 6px;
	margin-top: 20px;
	padding: 0px;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
}

.message .header{
	border-bottom: #e0e0e0 1px solid;
	padding-left: 20px;
	padding-right: 15px;
	padding-top: 15px;
	padding-bottom: 5px;
	/* height: 45px; */
}

.message .header .box{
	/* background-color: #6381A8; */
}

.message .header .checkbox{
	/* margin-top: -5px; */
	padding-left: 10px;
}

.message .header .but{
	border-bottom: #ffffff 2px solid;
	padding-left:  0px !important;
	padding-right: 0px !important;
	margin-left: 10px;
	margin-right: 10px;
	padding-bottom: 15px;
	font-family: 'FiraGOBold';
	font-feature-settings: 'case' on !important;
	font-weight: 800 !important;
}

.message .header .but:hover{
	border-bottom: #6381A8 2px solid;
	color: #6381A8;
	font-feature-settings: 'case' on !important;
	font-weight: 800 !important;
}

.message .header .but:focus{
	border-bottom: #6381A8 2px solid;
	color: #6381A8;
	font-feature-settings: 'case' on !important;
	font-weight: 800 !important;
}

.message .content{
	padding: 0px;
}

.message .content .list{
	padding: 0px!important;
	border-right: #e0e0e0 1px solid;
	height: calc(100vh - 355px);
	overflow: scroll;
	width: 100%;
	font-family: 'FiraGO', sans-serif !important;
	font-weight: 800 !important;
	text-transform: uppercase !important;
}


.message .content .list .inout{
	padding: 0px;
	border-radius: 0px;
	border: #e0e0e0 0px solid;
	border-bottom: #e0e0e0 1px solid;
	font-family: 'FiraGO', sans-serif !important;
	font-weight: 800 !important;
	font-size: 14px!important;
	color: #555B6E;
	width: 100%!important;
}

.message .content .list .inout:hover{
	padding: 0px;
	background-color: rgba(99,129,168, 0.1);
	border-radius: 0px;
	border: #e0e0e0 0px solid;
	border-bottom: #e0e0e0 1px solid;
	font-family: 'FiraGO', sans-serif !important;
	font-weight: 800 !important;
	text-transform: uppercase !important;
	font-size: 14px!important;
	color: #6381A8;
}

.message .content .list .inout:active{
	padding: 0px;
	background-color: rgba(99,129,168, 0.1);
	border-radius: 0px;
	border: #e0e0e0 0px solid;
	border-bottom: #e0e0e0 1px solid;
	font-family: 'FiraGO', sans-serif !important;
	font-weight: 800 !important;
	text-transform: uppercase !important;
	font-size: 14px!important;
	color: #6381A8;
}

.message .content .list .inout:focus{
	padding: 0px;
	background-color: rgba(99,129,168, 0.1);
	border-radius: 0px;
	border: #e0e0e0 0px solid;
	border-bottom: #e0e0e0 1px solid;
	font-family: 'FiraGO', sans-serif !important;
	font-weight: 800 !important;
	text-transform: uppercase !important;
	font-size: 14px!important;
	color: #6381A8;
}

.message .content .list .inout:link{
	padding: 0px;
	background-color: rgba(99,129,168, 0.1);
	border-radius: 0px;
	border: #e0e0e0 0px solid;
	border-bottom: #e0e0e0 1px solid;
	font-family: 'FiraGO', sans-serif !important;
	font-weight: 800 !important;
	text-transform: uppercase !important;
	font-size: 14px!important;
	color: #6381A8;
}

.message .content .list .inout:visited{
	padding: 0px;
	background-color: rgba(99,129,168, 0.1);
	border-radius: 0px;
	border: #e0e0e0 0px solid;
	border-bottom: #e0e0e0 1px solid;
	font-family: 'FiraGO', sans-serif !important;
	font-weight: 800 !important;
	text-transform: uppercase !important;
	font-size: 14px!important;
	color: #6381A8;
}
.message .content .list .inout:checked:focus{
	padding: 0px;
	background-color: rgba(99,129,168, 0.1);
	border-radius: 0px;
	border: #e0e0e0 0px solid;
	border-bottom: #e0e0e0 1px solid;
	font-family: 'FiraGO', sans-serif !important;
	font-weight: 800 !important;
	text-transform: uppercase !important;
	font-size: 14px!important;
	color: #6381A8;
}

.message .content .list .inout-active{
	padding: 0px !important;
	background-color: rgba(99,129,168, 0.1)!important;
	border-radius: 0px !important;
	border: #e0e0e0 0px solid;
	border-bottom: #e0e0e0 1px solid;
	font-family: 'FiraGO', sans-serif !important;
	font-weight: 800 !important;
	text-transform: uppercase !important;
	font-size: 14px!important;
	color: #6381A8;
	width: 100%!important;
}

/* width */
.message .content .list::-webkit-scrollbar {
	width: 5px;
}

/* Track */
.message .content .list::-webkit-scrollbar-track {
	background: #ffffff; 
}
   
/* Handle */
.message .content .list::-webkit-scrollbar-thumb {
	background: #e0e0e0; 
	border-radius: 2px;
}
  
/* Handle on hover */
.message .content .list::-webkit-scrollbar-thumb:hover {
	background: #e0e0e0; 
	border-radius: 6px;
}

.message .content .list .inout .checkbox{
	padding-top: 2px!important;
	padding-bottom: 2px!important;
	padding-left: 9px!important;
}

.message .content .list .inout .checkbox .name{
	margin-left: -10px!important;
	width: 240px!important;
}
.message .content .list .inout .checkbox .name-bold{
	margin-left: -10px!important;
	width: 240px!important;
	color: #6381A8;
	font-family: 'FiraGOBold', sans-serif !important; font-feature-settings: 'case' on !important; font-weight: 600 !important;
}

.message .content .list .inout-active .checkbox{
	padding-top: 2px!important;
	padding-bottom: 2px!important;
	padding-left: 9px!important;
}

.message .content .list .inout-active .checkbox .name{
	margin-left: -10px!important;
	width: 240px!important;
}



.message .message-box{
	padding-top: 20px!important;
	height: 485px;
	position: relative;
}

.message .message-box .title{
	/* border-bottom: #e0e0e0 1px solid; */
	padding-bottom: 17px;
	color: #555b6e!important;
	font-family: 'FiraGO', sans-serif !important;
	font-feature-settings: 'case' on !important;
	font-weight: 800 !important;
	text-transform: uppercase !important;
	font-size: 14px!important;
}

.message .message-box .text{
	font-family: 'FiraGO', sans-serif !important;
	color: #555b6e!important;
	font-weight: 800 !important;
	text-transform: uppercase !important;
	font-size: 16px!important;
	margin-bottom: 20px;
}

.message .message-box .message-box-send{
	padding-top: 10px!important;
	bottom: 0px;
	border-top: #e0e0e0 1px solid;
	position: absolute;
	width: 100%;
}

.messagesContainer {
	display: grid; 
	grid-template-columns: 1fr 5fr; 
	grid-template-rows: 1fr; 
	gap: 0px 0px; 
	grid-template-areas: 
	  ". ."; 
}
 .newMessage {
	margin-top: 25px;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(2, 1fr);
	grid-column-gap: 2px;
	grid-row-gap: 0px;
	/* width: 500px; */
}
.messages2 {
	width: 100%;
	margin: 2px;
}
.messages3 {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(2, 1fr) 8fr;
	grid-column-gap: 10px;
	grid-row-gap: 20px;
	padding: 10px;
}


.test{
	border-bottom: #e0e0e0 1px solid;
	padding: 10px;
}

.alert{
	font-size: 70px !important;
	color: #e89825;
	margin-bottom: 20px;
	margin-top: 5px;
}
