@font-face {
  font-family: "BOG 2017 Headline";
  src: url("../fonts/BOG2017Headline-SemiBold.eot");
  src: url("../fonts/BOG2017Headline-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/BOG2017Headline-SemiBold.woff2") format("woff2"),
    url("../fonts/BOG2017Headline-SemiBold.woff") format("woff"),
    url("../fonts/BOG2017Headline-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}