.service {
}

.service .change {
	background-color: #ffffff;
	border-radius: 6px !important;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px !important;
	color: #555b6e;
	padding: 10px;
	margin-bottom: 10px;
}
/* Left menu start */
.service .menu-list {
}

.service .menu-list .box {
	background-color: #ffffff;
	border-radius: 6px !important;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px !important;
	color: #555b6e;
	padding: 10px;
}

.service .menu-list .box .item {
	padding: 12px;
	font-family: "FiraGO", sans-serif !important;
	font-feature-settings: "case" on !important;
	font-weight: 900 !important;
	border-radius: 6px !important;
	color: #555b6e;
}

.service .menu-list .box .item:hover {
	background-color: rgba(99, 129, 168, 0.2);
	/* color: #6381a8 !important; */
}

.service .menu-list .box .item:hover > .icon {
	/* background-color: rgba(99,129,168, 0.2); */
	color: #6381a8 !important;
}

.service .menu-list .box .item:hover > .text {
	/* background-color: rgba(99,129,168, 0.2); */
	color: #6381a8 !important;
}

.service .menu-list .box .item .drop {
	/* background-color: rgba(99,129,168, 0.2); */
	color: #555b6e !important;
}
.service .menu-list .box .item:hover .drop {
	/* background-color: rgba(99,129,168, 0.2); */
	color: #6381a8 !important;
}

.service .menu-list .box .item-group {
	/* background-color: #f1eded; */
	border-radius: 0px !important;
	padding-top: 10px;
	padding-left: 20px;
	margin-top: 0px;
}

.service .menu-list .box .item-group .link {
	margin-bottom: 20px;
}

.service .menu-list .box .item-group .link span {
	margin-right: 20px;
}

/* Left menu end */

/* Service card Start */

.service-card{
	background-color: #ffffff !important;
	border-radius: 6px;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
}

.service-card .service-card-header{
	padding: 15px;
	border-bottom: #e0e0e0 1px solid;
}
.service-card .service-card-header .service-card-header-title{
	font-size: 14px;
	font-family: 'FiraGO', sans-serif !important;
	font-feature-settings: 'case' on !important;
	font-weight: 800 !important;
	margin-top: 10px!important;
}

.service-card .service-card-body{
	padding: 15px;
}

.service-card-body-current-box{
	background-color: #ffffff !important;
	border-radius: 6px;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
	padding: 15px;
}

.service-card-body-current-box .service-card-body-current-box-logo{
	color: #555b6e;
	margin-bottom: 10px;
	border-bottom: #e0e0e0 1px solid;
}

.service-card-body-current-box .service-card-body-current-box-text{
	color: #555b6e;
	margin-bottom: 5px;
	font-size: 14px;
}

.service-card-body-current-box .service-card-body-current-box-address{
	color: #555b6e;
	margin-top: 15px;
	margin-bottom: 5px;
	font-size: 14px;
}

.service-card-body-current-box .service-card-body-current-box-button{
	border-top: #e0e0e0 1px solid;
}

.service-card-dialog{
	padding: 0px;
}

.service-card-dialog .service-card-dialog-header{
	border-bottom: #e0e0e0 1px solid;
	margin-bottom: 10px;
	padding-bottom: 10px;
	font-size: 14px;
	font-family: 'FiraGO', sans-serif !important;
	font-feature-settings: 'case' on !important;
	font-weight: 800 !important;
}

.service-card-dialog .service-card-dialog-header .service-card-dialog-header-text{
	color: #6381a8;
	margin-bottom: 5px;
	font-feature-settings: 'case' on !important;
	font-size: 14px;
}
.service-card-dialog .service-card-dialog-header .service-card-dialog-header-text2{
	color: #555b6e;
	font-feature-settings: 'case' off !important;
	font-size: 14px;
}

.service-card-dialog .service-card-dialog-body{
	margin-top: 15px;
	margin-bottom: 15px;
}

.service-card-dialog .service-card-dialog-body .service-card-dialog-list{
	
}

.service-card-dialog .service-card-dialog-footer{
	border-top: #e0e0e0 1px solid;
	padding-top: 10px;
}
/* Service card end */

.service .service-box {
	background-color: #ffffff !important;
	border-radius: 6px;
	padding: 20px;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
}

.service .sms-center {
	/* background-color: #555B6E!important; */
	border-radius: 6px;
	padding: 10px;
	/* box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px; */
}

.service .sms-center-contact {
	background-color: #ffffff !important;
	border-radius: 6px;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
	height: calc(100vh - 281px);
	overflow: scroll;
}

.service .sms-center-contact .sms-center-contact-search {
	padding: 10px 10px 0px 10px;
}

.service .sms-center-contact .sms-center-number {
	border-bottom: #e0e0e0 1px solid;
	padding: 3px 10px !important;
	color: #555b6e;	
}

/* width */
.service .sms-center-contact::-webkit-scrollbar {
	width: 0px;
}

/* Track */
.service .sms-center-contact::-webkit-scrollbar-track {
	background: #ffffff; 
}
   
/* Handle */
.service .sms-center-contact::-webkit-scrollbar-thumb {
	background: #e0e0e0; 
	border-radius: 2px;
}
  
/* Handle on hover */
.service .sms-center-contact::-webkit-scrollbar-thumb:hover {
	background: #e0e0e0; 
	border-radius: 6px;
}

.service .sms-center-contact .sms-center-number .sms-center-number-icon {
	background: none !important;
	color: #ffffff !important;
}
.service .sms-center-contact .sms-center-number:hover .sms-center-number-icon {
	color: #555b6e!important;
}

.service .sms-center-message {
	background-color: #ffffff !important;
	border-radius: 6px;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
	height: calc(100vh - 281px);
	position: relative;
}

.service .sms-center-message .sms-center-message-name {
	border-bottom: #e0e0e0 1px solid;
	padding: 10px;
}

.service .sms-center-message .sms-center-message-text {
	padding: 10px;
	height: calc(100vh - 387px);
	overflow: scroll;

}

/* width */
.service .sms-center-message .sms-center-message-text::-webkit-scrollbar {
	width: 0px;
}

/* Track */
.service .sms-center-message .sms-center-message-text::-webkit-scrollbar-track {
	background: #ffffff; 
}
   
/* Handle */
.service .sms-center-message .sms-center-message-text::-webkit-scrollbar-thumb {
	background: #e0e0e0; 
	border-radius: 2px;
}
  
/* Handle on hover */
.service .sms-center-message .sms-center-message-text::-webkit-scrollbar-thumb:hover {
	background: #e0e0e0; 
	border-radius: 6px;
}

.service
	.sms-center-message
	.sms-center-message-text
	.sms-center-message-text-other {
	margin-bottom: 15px;
}
.service
	.sms-center-message
	.sms-center-message-text
	.sms-center-message-text-other-icon {
	float: left;
}

.bg-primary {
	background-color: rgba(99, 129, 168, 0.2) !important;
	color: #555b6e !important;
}

.service .sms-center-message .sms-center-message-text .left-sms .left-sms {
	width: 100%;
}
.service
	.sms-center-message
	.sms-center-message-text
	.left-sms
	.sms-center-message-text-other-text {
	padding: 10px;
	border-radius: 4px 10px 10px 10px;
	background-color: #e0e0e0;
	width: fit-content;
	float: left;
}

.service .sms-center-message .sms-center-message-text .left-sms .button {
	background: none !important;
	color: #ffffff !important;
	margin-left: 5px;
}

.service .sms-center-message .sms-center-message-text .left-sms:hover .button {
	background: none !important;
	color: #555b6e !important;
}

.service .sms-center-message .sms-center-message-text .date {
	margin-left: 60px !important;
}

.service
	.sms-center-message
	.sms-center-message-text
	.sms-center-message-text-me {
		position: relative;
		margin-bottom: 15px;
		height:60px;
}

.service
	.sms-center-message
	.sms-center-message-text
	.sms-center-message-text-me-text {
	padding: 10px;
	border-radius: 10px 4px 10px 10px;
	background-color: #6381a8;
	color: #ffffff;
	width: fit-content;
	float: left;
	right: 10px;
	position: absolute;
	height:fit-content;
	
}

.service
	.sms-center-message
	.sms-center-message-text
	.sms-center-message-text-me
	.date {
	position: absolute;
	margin-top: 45px;
	right: 10px;
}


.service .sms-center-message .sms-center-message-new {
	border-top: #e0e0e0 1px solid;
	position: absolute;
	bottom: 0px;
	width: 100%;
	padding: 10px;
	background-color: #ffffff;
}

.service
	.sms-center-message
	.sms-center-message-new
	.sms-center-message-new-change-phone {
	padding: 10px;
	background-color: #6381a8 !important;
}
