.header{

}


.status{
    font-family: 'FiraGO', sans-serif !important;
    font-feature-settings: 'case' on !important;
    font-weight: 800 !important;
    text-transform: uppercase !important;
    font-size: 14px!important;
}

.avatar{
    width: auto!important;
    padding: 5px;
    border-radius: 25px!important;
    /* background-color: #4f5050; */
}

.avatar .picture{
    /* color: #6381A8!important; */
    background-color: #6381A8!important;
    font-family: 'FiraGO', sans-serif !important;
    font-feature-settings: 'case' on !important;
    font-weight: 800 !important;
    float: left;
    border-radius: 50%;
}

.avatar .text{
   color: red!important;
   margin-left: 10px;
   float: left;
}

.avatar .text .status{
    color: #555B6E;
    font-size: 12px;
    font-family: 'FiraGO', sans-serif !important;
    font-weight: 800 !important;
}

.avatar .text .name{
    color: #6381A8;
    font-size: 14px;
    font-family: 'FiraGO', sans-serif !important;
    font-feature-settings: 'case' on !important;
    font-weight: 800 !important;
    margin-top: 4px;
}

.avatar .drop{
    margin-left: 10px;
    margin-top: 8px;
    float: left;
    margin-right: -16px;
 }

.nameStatus{
    color: #6381A8;
    font-size: 14px!important;
    font-family: 'FiraGO', sans-serif !important;
    font-feature-settings: 'case' on !important;
    font-weight: 800 !important;
    margin-top: 0px;
}

.profileDrop{
    font-size: 14px!important;
    font-family: 'FiraGO', sans-serif !important;
    /* font-feature-settings: 'case' on !important; */
    font-weight: 800 !important;
    color: #555B6E!important;

}

.profileDrop2{
    font-size: 14px!important;
    font-family: 'FiraGO', sans-serif !important;
    font-feature-settings: 'case' on !important;
    font-weight: 800 !important;

}

.company-avatar{
    padding: 8px;
    background-color: rgba(99,129,168, 0.2);
    color: #6381A8;
    width: 40px;
    border-radius: 50%;
    height: 40px;
    margin-right: 10px;
}

.sms {
    color: #555B6E!important;
}

.auth-right{
    margin-right: -25px!important;
}

.header-alert{
    width: 410px!important;
    padding: 20px;
}

.header-alert .header-alert-header{
    background-color: rgba(99,129,168, 0.2);
    font-size: 14px!important;
    font-family: 'FiraGO', sans-serif !important;
    font-feature-settings: 'case' on !important;
    font-weight: 800 !important;
    margin-top: -10px!important;
}

.header-alert .header-alert-body{
    margin: 0px;
    padding: 0px;
}

.header-alert .header-alert-body .header-alert-body-item{
    border-bottom: #e0e0e0 1px solid;
    padding: 1px 15px!important;
}

.header-alert .header-alert-body .header-alert-body-item .header-alert-body-avatar{
    background-color: rgba(99,129,168, 0.2);
    color: #555B6E;
    padding: 5px !important;
}