.home-page-section{

}

.home-page-section{

}

.home-page-section .home-page-box{
    width: 100%;
    background-color: #ffffff!important;
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px !important;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 20px;
    border: 1px solid #ffffff;
}

.home-page-section .home-page-box:hover{
    width: 100%;
    background-color: #ffffff!important;
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px !important;
    border: 1px solid #6381a8;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 20px;
}
