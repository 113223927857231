.page-conttainer{
    width: 1152px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 20px;
}
.grid-item {
    padding: 20px;
    font-size: 30px;
    text-align: center;
    background-color: #ffffff;
	border-radius: 6px !important;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px !important;
	color: #555B6E;
	padding: 10px;
}

.grid-item h1 {
    font-size: 16px;
    color: #6381a8;
    font-family: 'FiraGOBold', sans-serif !important; 
    font-feature-settings: 'case' on !important; 
    font-weight: 600 !important;
}
.grid-item p {
    font-size: 13px;
}
  
.about-picture {
    width: 300px;
    position: absolute;
    margin-right: 30px;
}

.about-picture .about-picture-img {
    width: 100%;
    border-radius: 10px;
}

.about-text {
    width: 822px;
    position: absolute;
    margin-left: 330px;
}

.about-text .about-text-card {
    background-color: #ffffff;
	border-radius: 6px !important;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px !important;
	color: #555B6E;
	padding: 10px 20px;
    
}

.about-text .about-text-card h1 {
    width: 100%;
    font-size: 16px;
}
.about-text .about-text-card p{
    width: 100%;
    color: #555B6E;
}

.faq-item{
    background-color: #ffffff;
	border-radius: 6px !important;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px !important;
	color: #555B6E;
	padding: 5px 10px 5px 20px;
    margin-bottom: 2px;
    transition: 1s;
    border: #ffffff 1px solid;
}

.faq-item h1{
    font-size: 16px;
    color: #6381a8;
    font-family: 'FiraGOBold', sans-serif !important; 
    font-feature-settings: 'case' on !important; 
    font-weight: 600 !important;
}

.faq-item p{
    font-size: 13px;
    color: #555B6E;
    display: none;
}

.faq-item:hover{
    background-color: #ffffff;
	border-radius: 6px !important;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px !important;
    /* border: #555B6E 1px solid; */
	color: #555B6E;
	padding: 5px 10px 5px 20px;
    transition: 1s;
}

.faq-item:hover > p{
    display: inline;
    transition: 1s;
}

.terms-of-service{
    background-color: #ffffff;
	border-radius: 6px !important;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px !important;
	color: #555B6E;
	padding: 5px 10px 5px 20px;
    font-size: 13px;
}