table, tr {
    font-family: 'FiraGO', sans-serif !important;
    border-collapse: collapse;
    width: 100%;
    color: #2f2f2f!important;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
  }
  
  .table-header{
    background-color: rgba(99,129,168, 0.2);
    color: #000000!important;
  }
  .table-header:hover {
    background-color: rgba(99,129,168, 0.2);
    color: #000000!important;
  }
  
  td, th {
    padding: 8px 8px;
    color: #000000!important;
    border-bottom: 1px solid #e0e0e0;
  }
  
  th {
    text-align: left;
    color: white;
  }

.payment-order{
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    color: #00000!important;
}

.payment-order .payment-order-header{
}

.payment-order .payment-order-header .payment-order-header-info{
    padding-top:10px; 
    width:60%; 
    float:left;
}

.payment-order .payment-order-header .payment-order-header-info .mt{
    margin-top: -15px;
}

.payment-order .payment-order-header .payment-order-header-logo{
    text-align: right; 
    padding-top:10px; 
    width:40%; 
    float:left;
}
.payment-order .payment-order-header .payment-order-header-logo p{
    margin-top: -10px;
    margin-bottom: 30px;
}

.payment-order .payment-order-header .payment-order-header-info p{
    margin-top: -10px;
}

.payment-order .payment-order-body{

}

.payment-order .payment-order-pay{
    padding-top:10px; 
    width:35%; 
    margin-left: 65%; 
    margin-top: 30px;
    margin-bottom: 30px;
}
.payment-order .payment-order-pay .payment-order-pay-p{
    float:left;
    margin-bottom: 15px;
}
.payment-order .payment-order-pay .payment-order-pay-span{
    text-align: right;
    margin-bottom: 15px;
}





.payment-order .payment-order-footer{
    margin-top: 30px;
}

.payment-order .payment-order-footer .payment-order-footer-sender{
    text-align: right; 
    padding-top:10px; 
    width:50%; 
    float:left;
}

.payment-order .payment-order-footer .payment-order-footer-sender p{
    margin-top: -8px;
}

.payment-order .payment-order-footer .payment-order-footer-receiver{
    padding-top:10px; 
    width:50%; 
    float:left;
}

.payment-order .payment-order-footer .payment-order-footer-receiver p{
    margin-top: -8px;
}

.stamp{
    position: relative;
    margin-top: -160px;
    margin-left: 220px;
    transform: rotate(340deg);
    opacity: 0.9;
}