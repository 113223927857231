.header-icon{
    background-color: rgba(99,129,168, 0.1) !important;
    border-radius: 15px !important;
    padding: 6px !important;
    color: #555B6E!important;
    margin-right: 15px!important;
    transition: 0.4s;
}
.header-icon:hover{
    background-color: rgba(99,129,168, 0.2) !important;
    border-radius: 15px !important;
    padding: 6px !important;
    color: #555B6E!important;
    margin-right: 15px!important;
    transition: 0.4s;
}

.avatar{
    width:fit-content
}
 .avatar-picture{
    background-color: #6381A8!important;
    font-family: 'FiraGO', sans-serif !important;
    font-feature-settings: 'case' on !important;
    font-weight: 800 !important;
    float: left;
    border-radius: 50%;
    color: #ffffff!important;
    width: 40px;
    height: 40px;
    padding-top: 7px;
}
.avatar-text{
    font-family: 'FiraGO', sans-serif !important;
    font-weight: 800 !important;
    font-feature-settings: 'case' on !important;
    color: #6381A8;
    text-decoration: none;
    font-size: 14px;
}

.avatar-text:hover{
    color: #555B6E;
}

.auth-right{
    margin-right: -25px!important;
}