.auth{

}

.auth .cover{
    height: 355px;
    width: 445px;
    background-color: #000000;
    background-image: url("../../../assets/images/login_bg.jpeg");
    background-repeat: no-repeat;
    background-position: center;
}

.auth .box{
    margin: 0px 80px 80px 80px;
}

.auth .box .title{
    font-size: 16px;
    font-family: 'FiraGO', sans-serif !important;
    font-feature-settings: 'case' on !important;
    font-weight: 900 !important;
    text-transform: uppercase !important;
}

.auth .box .auth-card{
    bottom: 0px;
}



.auth .box .auth-card .time{
    font-family: 'FiraGO', sans-serif !important;
    font-weight: 800 !important;
    font-size: 16px;
    margin-top: 0px;
    
}



