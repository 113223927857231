@font-face {
    font-family: 'FiraGO';
    src: url('font/FiraGO-ExtraLight.woff2') format('woff2'),
         url('font/FiraGO-ExtraLight.woff') format('woff');
         font-weight: 900 !important;
}

@font-face {
    font-family: 'FiraGOBold';
    src: url('font/FiraGO-Book.woff2') format('woff2'),
         url('font/FiraGO-Book.woff') format('woff');
         font-weight: 200 !important;
}


.primaryColor{
    background-color: #6381a8!important;
}

.secondarColor{
    background-color: #ACC5E1!important;
}

.ak{
   border-radius: 6px! important;
  
}

.aler{
    background-color: aqua;
}



