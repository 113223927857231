@font-face {
    font-family: "BOG 2018";
    src: url("../fonts/BOG2018-Medium.eot");
    src: url("../fonts/BOG2018-Medium.eot?#iefix") format("embedded-opentype"),
          url("../fonts/BOG2018-Medium.woff2") format("woff2"),
          url("../fonts/BOG2018-Medium.woff") format("woff"),
          url("../fonts/BOG2018-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}