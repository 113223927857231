.profile{
}

.profile .menu-list{
	
}

.profile .menu-list .box{
	background-color: #ffffff;
	border-radius: 6px !important;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px !important;
	margin-bottom: 6px !important;
	padding: 6px !important;
	color: #555B6E;
}

.profile .menu-list .box .item{
	margin-bottom: 10px !important;
	padding-top: 10px;
	padding-bottom: 10px;
}

.profile .menu-list .box .item:hover{
	margin-bottom: 10px !important;
	
	background-color: rgba(99, 129, 168);
	padding-top: 10px;
	padding-bottom: 10px;
	border-radius: 6px;
	color: #ffffff;
}


.profile .profile-box{
	background-color: #ffffff!important;
	border-radius: 6px;
	padding: 20px;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
}

.profile .profile-box .step-1{
	height: 420px !important;
	margin-top: 20px!important;
}

.profile .profile-box .step-1 .title{
	margin-bottom: 20px !important;
}

.profile .profile-box .step-1 .img-box{
	border: #e0e0e0 1px solid;
	padding: 10px;
	border-radius: 6px;
	margin-top: 40px;
	margin-bottom: 30px;
}

.profile .profile-box .step-1 .img-box .icon{
	width: 120px;
	margin-left: 130px;
	/* margin-bottom: 20px; */
	margin-top: 20px;
}

.profile .profile-box .step-2{
	height: 420px !important;
	margin-top: 20px!important;
}

.profile .profile-box .step-2 .title{
	margin-bottom: 20px !important;
}

.profile .profile-box .step-2 .img-box{
	border: #e0e0e0 1px solid;
	padding: 10px;
	border-radius: 6px;
	margin-top: 40px;
	margin-bottom: 30px;
}

.profile .profile-box .step-2 .img-box .icon{
	width: 120px;
	/* margin-left: 130px; */
	/* margin-bottom: 20px; */
	margin-top: 20px;
}

.profile .profile-box .step-3{
	height: 420px !important;
	margin-top: 20px!important;
}

.profile .profile-box .step-3 .title{
	
}

.profile .profile-box .step-3 .img-box{
	border: #e0e0e0 1px solid;
	padding: 20px;
	border-radius: 6px;
	margin-top: 5px;
	margin-bottom: 28px;
}

.profile .profile-box .step-4{
	height: 420px !important;
	margin-top: 20px!important;
}

.profile .profile-box .step-4 .title{
	margin-bottom: 23px !important;
}

.profile .profile-box .step-4 .br{
	margin-bottom: 29px !important;
}


.profile .profile-box .profile-page{
	margin-top: 10px;
}

.profile .profile-box .profile-page .profile-page-picture{
	
}

.profile .profile-box .profile-page .profile-page-picture .profile-page-picture-icon{
	background-color: #6381a8;
	border-radius: 60px;
	width: 120px;
	height: 120px;
	padding: 20px;
	color: #ffffff;
}

.profile .profile-box .profile-page .profile-page-content{
	margin-top: 10px;
}

.profile .profile-box .profile-page .profile-page-content ul, li{
    margin-bottom: 10px;
    font-family: 'FiraGO', sans-serif !important; 
    font-feature-settings: 'case' on !important; 
    font-weight: 800 !important;
	list-style-type: none;
	color: #555B6E!important;
}

.profile .profile-box .profile-page .profile-page-content  span{
    color: #555B6E;
    font-family: 'FiraGOBold', sans-serif !important; 
    font-feature-settings: 'case' on !important; 
    font-weight: 800 !important;
}


.profile-ip{

}

.profile-ip .title{
    border-bottom: #e0e0e0 1px solid;
	padding-bottom: 10px;
}

.profile-phone-mail{

}

.profile-phone-mail .title{
	border-bottom: #e0e0e0 1px solid;
	padding-bottom: 10px;
	margin-bottom: 20px;
}

.phone-mail-change-box{
	margin-bottom: 10px;
	width: 350px !important;
	padding-top: 50px;
	padding-bottom: 50px;
}
.phone-mail-change-box .phone-mail-change-box-title{
	margin-bottom: 20px;
}


.profile-deactivation{

}

.profile-deactivation .title{
	border-bottom: #e0e0e0 1px solid;
	padding-bottom: 10px;
	margin-bottom: 20px;
}









.MuiButton-root {
	border-radius: 8px;
	margin: 4px;
}
