.font{ font-family: 'FiraGO', sans-serif !important; font-feature-settings: 'case' on !important; font-weight: 800 !important;}
.font-p{ font-family: 'FiraGO', sans-serif !important; font-weight: 800 !important; }
.font-bold{ font-family: 'FiraGOBold', sans-serif !important; font-feature-settings: 'case' on !important; font-weight: 600 !important;}
.font-bold-p{ font-family: 'FiraGOBold', sans-serif !important; font-weight: 600 !important; }

body{
    font-family: 'FiraGO', sans-serif !important;
    font-weight: 800 !important;
    font-size: 13px!important;
    height: 100%!important;
    margin: 0;
}

.logo{
  color: #6381a8;
  text-decoration: none !important;
  margin-left: -20px !important;
  padding-top: 7px;
}

.logo .l1{
  font-family: 'Rubik', sans-serif;
  color: #6381a8;
  text-decoration: none;
}


.logo .l2{
  font-family: 'Rubik', sans-serif;
  color: #555B6E;
  text-decoration: none;
}

.content {

}

.page{

  padding-top: 40px!important;
  min-height: 100vh;
  min-height: calc(100vh - 182px);
  margin-bottom: 20px!important; 
  /* display:flex;  */
  /* flex-direction:column;  */
  /* justify-content:space-between; */
}
.breadcrumbs{
  background-color: #ffffff!important;
  padding-top: 5px!important;
  padding-bottom: 4px!important;
  padding-left: 3px;
  margin-bottom: 15px!important;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
}


.breadcrumbs .breadcrumbs-home{
  margin-top: 5px!important;
}

h1, h2, h3, h4, h5, h6{
    font-family: 'FiraGO', sans-serif !important;
    font-feature-settings: 'case' on !important;
    font-weight: 800 !important;
}

table, tr {
  font-family: 'FiraGO', sans-serif !important;
  border-collapse: collapse;
  width: 100%;
  color: #2f2f2f!important;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}

.table-header {
  background-color: rgba(99,129,168, 0.2);
  color: #555B6E;
}
.table-header:hover {
  background-color: rgba(99,129,168, 0.2);
  color: #555B6E;
}

td, th {
  padding: 8px 8px;
  color: #555B6E!important;
  border-bottom: 1px solid #e0e0e0;
}

tr:hover {
  background-color: rgba(99,129,168, 0.1);
}
th {
  text-align: left;
  color: white;
}

.w-100{  width: 100%!important; }

.br-6{ border-radius: 6px!important; }

.fs-12{ font-size: 12px!important; }
.fs-13{ font-size: 13px!important; }
.fs-14{ font-size: 14px!important; }
.fs-16{ font-size: 14px!important; }
.fs-16-2{ font-size: 16px!important; }
.fs-18{ font-size: 18px!important; }
.fs-42{ font-size: 42px!important; }

.m-1{ margin: 5px !important; }
.m-2{ margin: 10px !important; }
.m-3{ margin: 15px !important; }
.m-4{ margin: 20px !important; }
.m-5{ margin: 25px !important; }

.mt-1{ margin-top: 5px !important; }
.mt-2{ margin-top: 10px !important; }
.mt-3{ margin-top: 15px !important; }
.mt-4{ margin-top: 20px !important; }
.mt-5{ margin-top: 25px !important; }

.mb-1{ margin-bottom: 5px !important; }
.mb-2{ margin-bottom: 10px !important; }
.mb-3{ margin-bottom: 15px !important; }
.mb-4{ margin-bottom: 20px !important; }
.mb-5{ margin-bottom: 25px !important; }

.ml-1{ margin-left: 5px !important; }
.ml-2{ margin-left: 10px !important; }
.ml-3{ margin-left: 15px !important; }
.ml-4{ margin-left: 20px !important; }
.ml-5{ margin-left: 25px !important; }

.mr-1{ margin-right: 5px !important; }
.mr-2{ margin-right: 10px !important; }
.mr-3{ margin-right: 15px !important; }
.mr-4{ margin-right: 20px !important; }
.mr-5{ margin-right: 25px !important; }

.p-1{ padding: 5px !important; }
.p-2{ padding: 10px !important; }
.p-3{ padding: 15px !important; }
.p-4{ padding: 20px !important; }
.p-5{ padding: 25px !important; }

.pt-1{ padding-top: 5px !important; }
.pt-2{ padding-top: 10px !important; }
.pt-3{ padding-top: 15px !important; }
.pt-4{ padding-top: 20px !important; }
.pt-5{ padding-top: 25px !important; }

.pb-1{ padding-bottom: 5px !important; }
.pb-2{ padding-bottom: 10px !important; }
.pb-3{ padding-bottom: 15px !important; }
.pb-4{ padding-bottom: 20px !important; }
.pb-5{ padding-bottom: 25px !important; }

.pl-1{ padding-left: 5px !important; }
.pl-2{ padding-left: 10px !important; }
.pl-3{ padding-left: 15px !important; }
.pl-4{ padding-left: 20px !important; }
.pl-5{ padding-left: 25px !important; }

.pr-1{ padding-right: 5px !important; }
.pr-2{ padding-right: 10px !important; }
.pr-3{ padding-right: 15px !important; }
.pr-4{ padding-right: 20px !important; }
.pr-5{ padding-right: 25px !important; }


.text-primary{ color: #6381a8!important; }
.text-secondary{ color: #555B6E!important; }
.text-dark{ color: #2f2f2f!important; }
.text-success{ color: #357a38!important; }
.text-danger{ color: #aa2e25!important; }

.bg-primary{ background-color: #6381a8!important; }
.bg-secondary{ background-color: #555B6E!important; }
.bg-dark{ background-color: #2f2f2f!important; }

.button{
  font-family: 'FiraGO', sans-serif !important;
  font-feature-settings: 'case' on !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  height: 36px!important;
  border-radius: 6px!important;
  box-shadow: 0px 0px;
  background-color: #6381a8!important;
  color: #ffffff !important;
}

.button:link {
  font-family: 'FiraGO', sans-serif !important;
  font-feature-settings: 'case' on !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  height: 36px!important;
  border-radius: 6px!important;
  box-shadow: 0px 0px;
  background-color: #6381a8!important;
  color: #ffffff !important;
}

.button:visited {
  font-family: 'FiraGO', sans-serif !important;
  font-feature-settings: 'case' on !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  height: 36px!important;
  border-radius: 6px!important;
  box-shadow: 0px 0px;
  background-color: #6381a8!important;
  color: #ffffff !important;
}

.button:hover {
  font-family: 'FiraGO', sans-serif !important;
  font-feature-settings: 'case' on !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  background-color: #555B6E !important;
  height: 36px!important;
  border-radius: 6px!important;
  box-shadow: 0px 0px;
  color: #ffffff !important;
}

.button:active {
  font-family: 'FiraGO', sans-serif !important;
  font-feature-settings: 'case' on !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  background-color: #555B6E !important;
  height: 36px!important;
  border-radius: 6px!important;
  box-shadow: 0px 0px;
  color: #ffffff !important;
}

.button:focus {
  font-family: 'FiraGO', sans-serif !important;
  font-feature-settings: 'case' on !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  height: 36px!important;
  border-radius: 6px!important;
  box-shadow: 0px 0px;
  color: #ffffff !important;
}

.button-block{
  font-family: 'FiraGO', sans-serif !important;
  font-feature-settings: 'case' on !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  height: 36px!important;
  border-radius: 6px!important;
  box-shadow: 0px 0px;
  background-color: #555B6E!important;
  color: #ffffff !important;
}

.button-block:link {
  font-family: 'FiraGO', sans-serif !important;
  font-feature-settings: 'case' on !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  height: 36px!important;
  border-radius: 6px!important;
  box-shadow: 0px 0px;
  background-color: #555B6E!important;
  color: #ffffff !important;
}

.button-block:visited {
  font-family: 'FiraGO', sans-serif !important;
  font-feature-settings: 'case' on !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  height: 36px!important;
  border-radius: 6px!important;
  box-shadow: 0px 0px;
  background-color: #555B6E!important;
  color: #ffffff !important;
}

.button-block:hover {
  font-family: 'FiraGO', sans-serif !important;
  font-feature-settings: 'case' on !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  background-color: #555B6E !important;
  height: 36px!important;
  border-radius: 6px!important;
  box-shadow: 0px 0px;
  color: #ffffff !important;
}

.button-block:active {
  font-family: 'FiraGO', sans-serif !important;
  font-feature-settings: 'case' on !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  background-color: #555B6E !important;
  height: 36px!important;
  border-radius: 6px!important;
  box-shadow: 0px 0px;
  color: #ffffff !important;
}

.button-block:focus {
  font-family: 'FiraGO', sans-serif !important;
  font-feature-settings: 'case' on !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  height: 36px!important;
  border-radius: 6px!important;
  box-shadow: 0px 0px;
  color: #ffffff !important;
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 6px;
}


.cart{
  width: 500px;
  padding: 10px;
}

.home .cover{
  width: 100%;
  margin-bottom: 20px;
}

.home .section-1{
  margin-top: 20px;
  width: 100%;
  background-color: #555B6E!important;
  color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px !important;
  padding-top: 20px;
  padding-bottom: 20px;
}

.home .section-2{
  margin-top: 20px;
  width: 100%;
  padding-bottom: 10px;
}

.home .section-2 .section-2-pictue{
  margin-top: 20px;
  width: 100%;
  padding-bottom: 10px;
  border-radius: 8px;
  -webkit-filter: grayscale(100%);
}
.home .section-2 .section-2-pictue:hover{
  margin-top: 20px;
  width: 100%;
  padding-bottom: 10px;
  border-radius: 8px;
  -webkit-filter: grayscale(0%);
}

.home .section-2 .card{
  width: 100%;
  background-color: #ffffff!important;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px !important;
  padding: 10px;
  border-radius: 6px;
}

.home .section-2 .card .company_logo{
  border-bottom: #e0e0e0 1px solid;
  margin-bottom: 6px;
}
.home .section-2 .card .company_logo img{
  width: 60%;
}

.home .section-2 .card .title{
  font-size: 13px;
  font-family: 'FiraGO', sans-serif !important;
  font-feature-settings: 'case' on !important;
  font-weight: 800 !important;
  margin-bottom: 1px !important;
  color: #6381a8;
}
.home .section-2 .card .category{
  font-size: 11px;
  font-family: 'FiraGO', sans-serif !important;
  font-weight: 600 !important;
  
}



/* left nav start 
*/
.left-nav{

}

.left-nav .card{
	background-color: #ffffff;
	border-radius: 6px !important;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px !important;
	color: #555B6E;
	padding: 10px;
}

.left-nav .card .item{
	padding: 12px;
	font-family: 'FiraGO', sans-serif !important;
  font-feature-settings: 'case' on !important;
  font-weight: 900 !important;
  border-radius: 6px!important;
	color: #555B6E;
}

.left-nav .card .item:hover {
	background-color: rgba(99,129,168, 0.2);
	/* color: #6381a8 !important; */
}

.left-nav .card .item:hover > .icon {
	color: #6381a8 !important;
}

.left-nav .card .item:hover > .text {
	color: #6381a8 !important;
}


.left-nav .card .item-group{
    border-radius: 0px!important;
    padding-top: 10px;
    padding-left: 20px;
    margin-top: 0px;
}

.left-nav .card .item-group .link{
    margin-bottom: 20px;
}

.left-nav .card .item-group .link span{
    margin-right: 20px;
}
/* left nav end 
*/

/* pay-box start */
.pay-box{
  background-color: #ffffff;
	border-radius: 6px !important;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px !important;
	color: #555B6E;
	padding: 10px;

}

.pay-box .search{
  padding: 100px 200px;
}

.pay-box .search-show{
  padding: 10px 150px;
}

.pay-box .search .title{
  padding-bottom: 20px;
}

.pay-box .company_logo{
  border-bottom: #e0e0e0 1px solid;
  padding-bottom: 15px;
  padding-top: 10px;
  margin-bottom: 6px;
}
.pay-box .company_logo img{
  width: 80%;
}

.pay-box .title{
  font-size: 14px;
  font-family: 'FiraGO', sans-serif !important;
  font-feature-settings: 'case' on !important;
  font-weight: 800 !important;
  margin-bottom: 1px !important;
  color: #6381a8;
}

.pay-box .body{
  padding-top: 6px;
}

.pay-box .body .box-arrow{
  margin-top: -14px!important;
}



.pay-box .category{
  font-size: 14px;
  font-family: 'FiraGO', sans-serif !important;
  font-weight: 800 !important;
  
}

.pay-box .category2{
  font-size: 12px;
  font-family: 'FiraGO', sans-serif !important;
  font-weight: 800 !important;
  margin-bottom: 10px;
  
}

.pay-box .box{
  padding: 10px;
}

.pay-box .box .box-header{
  border-bottom: #e0e0e0 1px solid;
  margin-bottom: 10px;
}

.pay-box .box .box-header .box-company-logo img{
  width: 100px;
  border-radius: 6px;
}

.pay-box .box .box-body{
  margin-top: 15px;
  margin-bottom: 15px;
}

.pay-box .box .box-body .text{
  font-size: 14px;
  margin-bottom: 5px;
}

.pay-box .box .box-body .price{
  font-size: 16px;
  color: #6381a8;
  margin-bottom: 5px;
}

.pay-box .box .box-body .more{
  font-size: 14px;
}

.pay-box .box .box-body .icon{
  width: 14px;
}

.pay-box .box .box-footer{
  border-top: #e0e0e0 1px solid;
  padding-top: 10px;
}


.pay-box-dialog{
  /* width: 1100px; */
}

.pay-box-dialog .header{
  border-bottom: #e0e0e0 1px solid;
  margin-bottom: 10px;
}
.pay-box-dialog .header .title{
  font-size: 14px;
  font-family: 'FiraGO', sans-serif !important;
  font-feature-settings: 'case' on !important;
  font-weight: 800 !important;
  margin-bottom: 1px !important;
  color: #6381a8;
}

.pay-box-dialog .header .category{
  font-size: 13px;
  font-family: 'FiraGO', sans-serif !important;
  font-weight: 800 !important;
  margin-bottom: 10px !important;
  color: #555B6E;
}

.pay-box-dialog .header .company-logo img{
  width: 100px;
}

.pay-box-dialog .body{
  margin-top: 15px;
  margin-bottom: 15px;
}

.pay-box-dialog .company-info{
  margin-top: 20px;
  margin-bottom: 15px;
  /* border: #e0e0e0 1px solid; */
  border-radius: 6px;
  /* padding: 15px; */
  /* background-color: rgba(99,129,168, 0.1); */
  /* background-color: #555B6E;
  color: #ffffff; */
  color: #555B6E;
}

.pay-box-dialog .company-info .company-info-header{
  margin-bottom: 30px;
  font-size: 14px;
}

.pay-box-dialog .company-info .company-info-list{
  margin-top: 5px;
  margin-bottom: 20px;
}

.pay-box-dialog .pay-box-dialog-footer{
  border-top: #e0e0e0 1px solid;
  padding-top: 10px;
}

/* pay-box end */


/* Cart Start */
.cart{
  width: 100%;

}

.cart .product{
  background-color: #ffffff;
	border-radius: 6px !important;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px !important;
	color: #555B6E;
	padding: 15px;
}

.cart .price{
  background-color: #ffffff;
	border-radius: 6px !important;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px !important;
	color: #555B6E;
	padding: 20px;
}
.cart .price .item{
  font-size: 14px;
  margin-bottom: 15px !important;
}

/* Cart End */


/* Pay history start */

.pay-history{
  background-color: #ffffff;
	border-radius: 6px !important;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px !important;
	color: #555B6E;
	padding: 10px;
}

/* Pay history end */


.error404{
  margin-top: 20px;
  width: 100%;
  min-height: 100vh;
  min-height: calc(100vh - 250px);
  background-color: #ffffff!important;
  color: #555B6E;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px !important;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
}




.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.themeDark {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: white;
}

.themeLight {
	background-color:#f8fafc;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	color: black;
 }

.themeCenter {
	min-height: 80vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
}

.dialogStyle {
	min-height: 50vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
}

.header {
	font-family: 'BPG Arial Caps';
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



