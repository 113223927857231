.home-cover{
    background-color: #ffffff;
    margin-top: -0px!important;
    margin-bottom: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.home-cover .home-cover-hr{
    height: 5px !important;
    width: 100px !important;
    background: #6381a8!important;
    border-radius: 1px;
    margin-bottom: 25px;
    margin-top: 55px;
}

.home-cover .home-cover-slide{
    position: relative;
    background-image: url("../../../assets/images/home_cover_slide_bg_logo.png");
    width: 350px;
    height: 322px;
    background-size: 350px 322px;
    margin-top: -15px;
}


.home-cover .home-cover-slide .home-cover-slide-picture{
    border-radius: 50%;
    width: 280px;
    height: 280px;
    /* position: absolute; */
    margin-top: 25px;
    margin-left: 43px;
    object-fit: cover
}

.home-cover .home-cover-slide .home-cover-slide-icon{
    position: absolute;
    /* background-image: url("../../../assets/images/home_cover_slide_bg_icon.png"); */
    width: 350px;
    height: 322px;
    background-size: 350px 322px;
    margin-top: -15px;
}

.home-cover .home-cover-slide .home-cover-slide-icon-icon{
    width: 77px!important;
    position: absolute;
    margin-top: -65px;
    margin-left: 40px;
    border-radius: 50%;
}

.home-cover .home-cover-slide .home-cover-slide-line{
    position: absolute;
    width: 350px;
    height: 322px;
    margin-top: -310px;
    margin-left: 7px;
}

.rotate {
    animation: rotation 8s infinite linear;
}
  
@keyframes rotation {
    0%     { transform: rotate(30deg); }
    50%    { transform: rotate(60deg); }
    100%   { transform: rotate(30deg); }
}

