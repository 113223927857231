.footer{
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
}

.footer .menu{
    padding: 10px 0px 5px 0px;
    background-color: #fff;
}

.footer .menu .link{
    font-size: 14px!important;
    text-decoration: none;
    color: #555B6E!important;
}

.footer .menu .link:hover{
    font-size: 14px!important;
    text-decoration: none;
    color: #6381a8!important;
}

.footer .menu .link-active{
    font-size: 14px!important;
    text-decoration: none;
    color: #6381a8!important;
}

.footer .menu .logo{
    margin-left: 10px!important;
    background-color: #fff;
}

.footer .copyright{
    font-family: 'FiraGO', sans-serif !important;
    font-weight: 800 !important;
    padding-top: 7px ;
    border-top: 1px solid #555B6E;
    background-color: #6381a8!important;
    color:#fff
}

.footer .copyright .soc{
    color:#fff;
    margin-left: 10px;
}