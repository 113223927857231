.nav{
    background-color: #6381a8!important;
    padding-top: 5px;
    padding-bottom: 3px;
    position: fixed;
    width: 100%;
    z-index: 999;
}

.nav .item{
    font-size: 14px!important;
    color: #e0e6ee;
    background-color: #6381a8;
    text-decoration: none;
    font-family: 'FiraGO', sans-serif !important;
    font-feature-settings: 'case' on !important;
    font-weight: 800 !important;
    font-size: 14px!important;
    border-top:#6381a8 0px solid;
    border-left:#6381a8 0px solid;
    border-right:#6381a8 0px solid;
    border-bottom:#6381a8 1px solid;
    padding: 0px 0px;
    margin-right: 20px;
    border-radius: 0px;
}

.nav .item:link {
    font-size: 14px!important;
    color: #ffffff;
    background-color: #6381a8;
    text-decoration: none;
    font-family: 'FiraGO', sans-serif !important;
    font-feature-settings: 'case' on !important;
    font-weight: 800 !important;
    border-top:#6381a8 0px solid;
    border-left:#6381a8 0px solid;
    border-right:#6381a8 0px solid;
    border-bottom:#6381a8 1px solid;
    border-radius: 0px;
  }
  
.nav .item:visited {
    font-size: 14px!important;
    color: #ffffff;
    background-color: #6381a8;
    text-decoration: none;
    font-family: 'FiraGO', sans-serif !important;
    font-feature-settings: 'case' on !important;
    font-weight: 800 !important;
    border:#6381a8 0px solid;
    border-radius: 0px;
  }
  
  .nav .item:hover {
    font-size: 14px;
    color: #ffffff;
    background-color: #6381a8;
    text-decoration: none;
    font-family: 'FiraGO', sans-serif !important;
    font-feature-settings: 'case' on !important;
    font-weight: 800 !important;
    border-radius: 0px;
    border-bottom:#ffffff 1px solid !important;
  }
  
  .nav .item:active {
    font-size: 14px;
    color: #ffffff;
    background-color: #6381a8;
    text-decoration: none;
    font-family: 'FiraGO', sans-serif !important;
    font-feature-settings: 'case' on !important;
    font-weight: 800 !important;
    border-bottom:#555B6E 1px solid !important;
    border-radius: 0px;
  }

  .nav .item:focus {
    font-size: 14px;
    color: #ffffff;
    text-decoration: none;
    font-family: 'FiraGO', sans-serif !important;
    font-feature-settings: 'case' on !important;
    font-weight: 800 !important;
    border-radius: 0px;
    border-bottom:#ffffff 1px solid !important;
  }

  .nav  .item-active {
    font-size: 14px!important;
    color: #ffffff;
    background-color: #6381a8;
    text-decoration: none;
    font-family: 'FiraGO', sans-serif !important;
    font-feature-settings: 'case' on !important;
    font-weight: 800 !important;
    font-size: 14px!important;
    border-top:#6381a8 0px solid;
    border-left:#6381a8 0px solid;
    border-right:#6381a8 0px solid;
    border-bottom:#ffffff 1px solid !important;
    padding: 0px 0px;
    margin-right: 20px;
    border-radius: 0px;
    
  }

  .nav  .lang-group{
    padding-top: 6px !important;
   
  }

  .nav .lang-group .lang{
    color: #e0e6ee!important;
    padding-top: 10px !important;
    padding-left: 15px!important;
    margin-top: 10px!important;
    font-size: 14px !important;
    font-family: 'FiraGO', sans-serif !important;
    font-feature-settings: 'case' on !important;
    text-decoration: none;
    font-weight: 900 !important;
  }

  .nav .lang-group .lang:hover{
    color: #fff !important;
    text-decoration: none;
    font-weight: 900 !important;
  }

  .nav .lang-group .lang-active{
    color: #ffffff!important;
    padding-top: 10px !important;
    padding-left: 15px!important;
    margin-top: 10px!important;
    font-size: 14px !important;
    font-family: 'FiraGO', sans-serif !important;
    font-feature-settings: 'case' on !important;
    text-decoration: none;
    font-weight: 900 !important;
  }